import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  // 关于我们
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  // 产品
  {
    path: '/contactUs',
    name: 'contactUs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactUsView.vue')
  },
  // 证书查询
  {
    path: '/certificateQuery',
    name: 'certificateQuery',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/CertificateQueryView.vue')
  },
  {
    path: '/ZJTFile',
    name: 'ZJTFile',
    component: () => import(/* webpackChunkName: "about" */ '../views/ZJTFile.vue')
  },
  {
    path: '/cerServices',
    name: 'cerServices',
    meta: { title: '认证服务' },
    component: () => import('../views/component/cerServices.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
