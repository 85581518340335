<template>
  <div class="page-container">
    <!-- 导航栏 -->
    <!-- <HelloWorld/> -->
    <Top />
    <!-- 内容区域 -->
    <div class="content-area">
      <!-- 轮播图自动播放轮播图 -->
      <el-carousel :interval="5000" arrow="always" :autoplay="true" class="full-width-carousel">
        <el-carousel-item v-for="item in carouselItems" :key="item">
          <img :src="item"  class="full-width-image">
        </el-carousel-item>
      </el-carousel>

      <!-- 图片展示 -->
      <div class="image-gallery">
        <img
          v-for="(image, index) in images"
          :key="index"
          :src="image"
          class="image-gallery-item"
        />
      </div>
    </div>

    <!-- 页面底部 -->
    <!-- <footer class="footer">
      <div v-for="(info, index) in footerInfo" :key="index" class="footer-item">
        <component :is="footerIcons[index]" class="footer-icon" />
        <p>{{ info }}</p>
      </div>
    </footer> -->
  </div>
</template>

<script>
import Top from './component/TopView'
export default {
  name: 'AboutView',
  components: {
    Top
  },
  data () {
    return {
      carouselItems: [
      require('@/assets/home1.jpg'),
      require('@/assets/home2.jpg'),
      require('@/assets/home3.jpg')
      ],
      images: [
      require('@/assets/content.jpg'),
      require('@/assets/content2.jpg')
      ]
    }
  }
}

</script>

<style scoped>
.page-container {
  width: 100%;
  position: relative;
  /* min-height: 100vh; */
  background-color: #f5f7fa; /* 设置一个背景颜色 */
  display: flex;
  flex-direction: column;
  padding-top: 110px;
  /deep/.el-carousel__container{
    position: relative;
    /* height: auto; */
    /* height: 550px; */
  }
}

.el-menu-demo {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8); /* 半透明背景 */
}


.full-width-carousel {
  width: 100%;
  /* height: 550px; */
  margin-bottom: 20px;
}

.full-width-image {
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 600px) {
  .full-width-image {
    height: 15vh; /* 小屏幕时设置为视口高度的13% */
    object-fit: contain;
  }

  :deep(.el-carousel__container) {
    position: relative;
    height: 15vh; /* 小屏幕时设置为视口高度的13% */
  }
}

@media (min-width: 601px) {
  .full-width-image {
    height: 55vh;  /*大屏幕时设置为视口高度的50%*/
    object-fit: contain;
  }
  :deep(.el-carousel__container) {
    position: relative;
     height: 55vh; /*小屏幕时设置为视口高度的13% */
  }
}


.image-gallery {
  display: flex;
  flex-direction: row; /* 垂直排列 */
  justify-content: center; /* 垂直居中 */
  align-items: center; /* 水平居中 */
  margin: 20px 0;
  width: 100%;
  .image-gallery-item{
    width: 30%;
    /* height: 750px; */
    margin-right: 60px;
  }
}

.hover-effect {
  transition: transform 0.3s ease;
}

.hover-effect:hover {
  transform: scale(1.1);
}

.footer {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* 半透明背景 */
  text-align: center;
  padding: 20px 0;
}

.footer-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 0;
}

.footer-icon {
  margin-right: 10px;
  width: 16px; /* 设置图标宽度 */
  height: 16px; /* 设置图标高度 */
}

</style>
